import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { inject } from '@angular/core';
import { isRetrieveUserCompleted, selectUserPermissions } from '@core/store/selectors/core.selectors';
import { map, Observable, skipWhile, take } from 'rxjs';
import { concatLatestFrom } from '@ngrx/operators';
import { PermissionEnum } from '@core/enums/permission.enum';

export const hasPermissionsGuard: CanMatchFn = (route, segments): Observable<boolean | UrlTree> => {
  const store: Store = inject(Store);
  const router: Router = inject(Router);

  const permissions: PermissionEnum[] = route.data['permissions'];
  const atLeastPermissions: PermissionEnum[] = route.data['atLeastPermissions'];

  return store.select(isRetrieveUserCompleted).pipe(
    skipWhile(isRetrieveUserCompleted => !isRetrieveUserCompleted),
    take(1),
    concatLatestFrom(() => store.select(selectUserPermissions).pipe(
      take(1),
      map(userPermissions => {
        if(!!permissions?.length) return permissions.every(permission => userPermissions.includes(permission))
        else if(!!atLeastPermissions?.length) return userPermissions.some(userPermission => atLeastPermissions.includes(userPermission));
        else return true;
      })
    )),
    map(([, hasAuthorization]) => !!hasAuthorization || router.createUrlTree(['']))
  );
};
